import React from "react";
import loadable from "@loadable/component";
import InternalLink from "./InternalLink";
import { Heading } from "../sdk/Layout";
import BGColor from "./BGColor";
import LeadForm from "./LeadForm/index";
import retry from "../../componentLoader";
import Spacer from "./Spacer";

const Slider = loadable(() => retry(() => import("./Slider")));
const SliderB = loadable(() => retry(() => import("./SliderB/index")));
const SliderC = loadable(() => retry(() => import("./SliderC/index")));
const SliderD = loadable(() => retry(() => import("./SliderD/index")));
const Benefits = loadable(() => retry(() => import("./Benefits/index")));
const AboutUs = loadable(() => retry(() => import("./AboutUs/index")));
const Features = loadable(() => retry(() => import("./Features/index")));
const Mosaic = loadable(() => retry(() => import("./Mosaic/index")));
const Brands = loadable(() => retry(() => import("./Brands/index")));
const BrandSelector = loadable(() => retry(() => import("./BrandSelector/index")));
const ActionCTA = loadable(() => retry(() => import("./ActionCTA/index")));
const MenuList = loadable(() => retry(() => import("./MenuList/index")));
const InstagramFeed = loadable(() => retry(() => import("./InstagramFeed")));
const CTABlock = loadable(() => retry(() => import("./CTABlock/index")));
const Testimonial = loadable(() => retry(() => import("./Testimonial/index")));

const YourBrands = loadable(() => retry(() => import("./YourBrands/index")));
const ProductGridWithLogic = loadable(() =>
    retry(() => import("./ProductGridWithLogic/index"))
);
const Editor = loadable(() => retry(() => import("./Editor/index")));
const TransparentHeader = loadable(() =>
    retry(() => import("./TransparentHeader/index"))
);
const NewsfeedActions = loadable(() =>
    retry(() => import("./NewsfeedActions"))
);
const Banner = loadable(() => retry(() => import("./Banner")));
const Links = loadable(() => retry(() => import("./Links")));
const Faqs = loadable(() => retry(() => import("./Faqs")));
const Featured = loadable(() => retry(() => import("./Featured")));
const Tutorials = loadable(() => retry(() => import("./Tutorials")));
const CTACollection = loadable(() =>
    retry(() => import("./CTACollection/index"))
);
const ParagraphCustomField = loadable(() =>
    retry(() => import("./ParagraphCustomField/index"))
);

const ScriptInjection = loadable(() =>
    retry(() => import("./ScriptsInjection"))
);

const AmbientBlock = loadable(() =>
    retry(() => import("./AmbientBlock/index"))
);
const ImageBlock = loadable(() => retry(() => import("./ImageBlock")));
const FeaturedImage = loadable(() => retry(() => import("./FeaturedImage")));
const TrendingCategories = loadable(() =>
    retry(() => import("./TrendingCategories"))
);
const Audio = loadable(() => retry(() => import("./Audio")));
const SubCategorias = loadable(() => retry(() => import("./SubCategorias")));
const Departments = loadable(() => retry(() => import("./Departments")));
const InfoSection = loadable(() => retry(() => import("./InfoSection/index")));
const BackBTN = loadable(() => retry(() => import("./BackBTN/index")));
const VideoFeed = loadable(() => retry(() => import("./VideoFeed/index")));
const CTAPrivateLabel = loadable(() => retry(() => import("./CTAPrivateLabel/index")));

const componentsForRegistration = [
    Slider,
    SliderB,
    SliderC,
    SliderD,
    Benefits,
    AboutUs,
    BackBTN,
    InfoSection,
    Mosaic,
    Features,
    Testimonial,
    ActionCTA,
    CTABlock,
    CTACollection,
    MenuList,
    AmbientBlock,
    ImageBlock,

    FeaturedImage,
    Editor,
    VideoFeed,
];

if (typeof window !== "undefined" && window.__REGISTERED_LOGIN_HANDLERS__) {
    try {
        window.__REGISTERED_LOGIN_HANDLERS__.push(() => {
            console.log("registered all custom fields");
            componentsForRegistration.map((index) => {
                if (index && index.preload) index.preload();
            });
        });
    } catch (err) {}
}

function getComponent(index, config, i, args) {
    const {
        _t,
        location,
        lang,
        childCollection,
        recomendations,
        toggleModalProduct,
        viewItem,
        gotoThankYouPage,
        defaultTags,
    } = args;
    if (index.toLowerCase().indexOf("h2") > -1) {
        return (
            <Heading isCentered key={"section-pragraph" + i}>
                <h3>{config}</h3>
            </Heading>
        );
    }

    switch (index.toLowerCase()) {
        case "slider":
            return <Slider key={"section-slider" + i} config={config} />;
        case "slider_b":
            return <SliderB key={"section-b-bslider" + i} config={config} />;
        case "slider_c":
            return <SliderC key={"section-c-bslider" + i} config={config} />;
        case "slider_d":
            return <SliderD key={"section-d-bslider" + i} config={config} />;
        case "benefits":
            return <Benefits key={"section-benefits" + i} config={config} />;
        case "aboutus":
            return (
                <AboutUs key={"section-acountus" + i} config={config} _t={_t} />
            );

        case "mosaic":
            return <Mosaic key={"section-mosaic1" + i} config={config} />;
        case "back_btn":
            return <BackBTN key={"section-back" + i} config={config} />;
        case "mosaic 2":
            return <Mosaic key={"section-mosaic2" + i} config={config} />;
        case "brands":
            return <Brands key={"section-brands" + i} config={config} />;
        case "brandselector":
            return <BrandSelector key={"section-brandSelector" + i} config={config} _t={_t} />;
        case "yourbrands":
            return <YourBrands key={"YourBrands-brands" + i} config={config} />;
        case "actions":
            return <ActionCTA key={"section-actions" + i} config={config} />;
        case "instagram":
            if (config === "" || config == null) return "";
            return (
                <InstagramFeed key={"section-instagram" + i} config={config} />
            );
        case "cta-block":
            return <CTABlock key={"cta-blog" + i} config={config} />;
        case "testimonial":
            return (
                <Testimonial key={"testimonial" + i} config={config} _t={_t} />
            );
        case "editor":
            return <Editor key={"editor" + i} config={config} />;
        case "faqs":
            return <Faqs key={"faqs" + i} config={config} />;
        case "tutorials":
            return <Tutorials key={"tutorials" + i} config={config} />;
        case "editor1":
            return <Editor key={"editor" + i} config={config} />;
        case "editor2":
            return <Editor key={"editor" + i} config={config} />;

        case "script":
            return <ScriptInjection key={"script" + i} config={config} />;
        case "ctacollection":
            return <CTACollection key={"cta-collection" + i} config={config} />;
        case "paragraphcustomfield":
            return (
                <ParagraphCustomField
                    key={"paragraph-custom-field" + i}
                    config={config}
                />
            );
        case "features":
            return <Features key={"features" + i} config={config} />;
        
        case "ctaleftimage":
            return <CTAPrivateLabel key={"CTAPrivateLabel" + i} config={config} />;

        case "links":
            return <Links key={"Links-for-apps" + i} config={config} />;
        case "transparentheader":
            return (
                <TransparentHeader
                    key={"TransparentHeader" + i}
                    config={config}
                />
            );
        case "productgrid":
            return (
                <ProductGridWithLogic
                    key={"productgrid" + i}
                    viewItem={viewItem}
                    toggleModalProduct={toggleModalProduct}
                    config={config}
                />
            );
        case "departments":
            return <Departments key={`departments-${i}`} data={config} />;
        case "videos":
            return <VideoFeed key={`videos-${i}`} config={config} />;
        case "banner":
            return <Banner key={`banner-custom-field-${i}`} config={config} />;
        case "newsfeedactions":
            return (
                <NewsfeedActions key={`newsfeedactions-${i}`} config={config} />
            );
        case "trendingcategories":
            return (
                <TrendingCategories
                    key={`trending-categories-${i}`}
                    config={config}
                />
            );
        case "featured":
            return <Featured key={`featured-${i}`} config={config} />;
        case "leadform2":
            return (
                <LeadForm
                    key={"lead-form" + i}
                    config={config}
                    _t={_t}
                    searchQuery={location.search}
                    onSuccess={gotoThankYouPage}
                    pathname={location.pathname}
                />
            );
        case "menus":
            return (
                <MenuList
                    key={"section" + i}
                    config={config}
                    strings={{
                        seeMore: _t("seeMore"),
                        seeLess: _t("seeLess"),
                    }}
                />
            );
        case "ambientblock":
            return (
                <AmbientBlock
                    childCollection={childCollection}
                    config={config}
                    pathname={location.pathname}
                    title={_t("allAmbients")}
                    key={"ambient-block-pragraph" + i}
                />
            );
        case "imageblock":
            return (
                <ImageBlock config={config} key={"image-block-pragraph" + i} />
            );
        case "bgcolor":
            return <BGColor BGColor config={config} key={"bg-color" + i} />;
        case "featuredimage":
            return (
                <FeaturedImage
                    config={config}
                    key={"image-featured-pragraph" + i}
                />
            );
        case "audio":
            return <Audio config={config} key={"audio-custom-field" + i} />;
        case "subcategorias":
            return (
                <SubCategorias
                    config={config}
                    defaultTags={defaultTags}
                    key={"sub-categorias-custom-field" + i}
                />
            );
        case "infosection":
            return (
                <InfoSection
                    config={config}
                    key={"info-section-custom-field" + i}
                />
            );
        case "separator":
            return (
                <Spacer
                    config={config}
                    key={"spacer-section-custom-field" + i}
                />
            );
        default:
            return null;
    }
}

export default function CustomField({
    customFields,
    lang,
    _t,
    location,
    top,
    childCollection,
    toggleModalProduct,
    viewItem,
    recomendations,
    gotoThankYouPage,
    defaultTags,
}) {
    if (customFields == null) return null;
    let customfieldsBottom = [];
    let topCustomFields = [];
    if (customFields) {
        customfieldsBottom = Object.keys(customFields);
        if (customfieldsBottom.indexOf("Slider") > -1) {
            customfieldsBottom = customfieldsBottom.filter(
                (index) => index !== "Slider"
            );
            topCustomFields.push("Slider");
        }
    }

    const customfieldsKeys = top ? topCustomFields : customfieldsBottom;

    return customfieldsKeys.map((index, i) =>
        getComponent(index, customFields[index], i, {
            lang,
            _t,
            location,
            childCollection,
            recomendations,
            toggleModalProduct,
            gotoThankYouPage,
            viewItem,
            defaultTags,
        })
    );
}
