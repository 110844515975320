import styled, { css } from "styled-components";
import { Columns, Column } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Dropdown } from "../../sdk/Elements";

export const DemoLayout = styled.div`
    height: calc(100% - 200px);
`;

export const Footer = styled.footer`
    background-color: ${(props) => props.theme.grayLight};
    border-top: solid 1px ${(props) => props.theme.borderColor};
    padding-top: 50px;
    margin-top: 0px;
    & ${Columns} {
        flex-wrap: wrap;
        margin: 0;
    }
    @media (max-width: 980px) {
        padding-top: 25px;
    }
    @media (max-width: 768px) {
        & ${Column} {
            padding: 0;
            margin: 0;
        }
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        display: none;
    }
`;

export const Brand = styled(Column)`
    width: 30%;
    > svg {
        fill: ${(props) => props.theme.primaryDarkMode};
        margin-left: -9px;
        &.mayorea-logo {
            fill: rgb(17, 44, 85);
            margin-left: -9px;
            max-width: 241px;
            width: 100%;
            margin-left: 1px;
        }
    }
    h3 {
        margin: 0;
        padding: 0;
        font-size: 22px;
        margin-bottom: 20px;
        color: ${(props) => props.theme.primaryDark};
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        max-width: 250px;
        color: ${(props) => props.theme.gray};
        a {
            text-decoration: underline;
            white-space: nowrap;
            cursor: pointer;
        }
    }
    a {
        color: ${(props) => props.theme.gray};
        &:hover {
            color: ${(props) => props.theme.primaryDark};
        }
    }

    > div {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 20px;
    }

    @media (max-width: 980px) {
        width: 100% !important;
        text-align: center;
        p {
            max-width: 100%;
        }
        > div {
            justify-content: center;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 20px !important;
        > svg {
            width: 120px;
            height: 30px;
            margin-bottom: 10px;
        }
        h3 {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }
`;

export const Social = styled.div`
    display: flex;
    flex-wrap: wrap;
    svg {
        width: 18px;
        height: 18px;
        color: ${(props) => props.theme.gray};
    }
    a {
        border: solid 2px;
        color: ${(props) => props.theme.gray};
        border-radius: 3px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        & + a {
            margin-left: 5px;
        }
        &:hover {
            opacity: 1;
        }
    }
`;

export const Appstore = styled.div`
    margin-left: 10px;
    svg {
        width: 80px;
        height: 30px;
    }
    a {
        display: block;
        line-height: 0;
        opacity: 0.8;
        &:hover {
            opacity: 1;
        }
    }
`;

export const Nav = styled.nav`
    h3 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 20px;
        color: ${(props) => props.theme.primaryDark};
        svg {
            display: none;
            float: right;
            width: 15px;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            & + li {
                margin-top: 5px;
            }
            a {
                display: inline-block;
                padding: 5px 0;
                font-size: 12px;
                color: ${(props) => props.theme.primaryDark};
                border-bottom: solid 1px transparent;
                transition: all 200ms linear;
                &:hover {
                    border-bottom-color: ${(props) => props.theme.primaryDark};
                }
            }
        }
    }

    @media (max-width: 768px) {
        background-color: ${(props) => props.theme.white};
        h3 {
            margin-bottom: 0;
            padding: 10px;
            cursor: pointer;
            svg {
                display: block;
            }
            &.is-active {
                svg {
                    transform: rotate(180deg);
                }
                & + ul {
                    display: block !important;
                }
            }
        }
        ul {
            padding: 0px 5px 10px 20px;
            display: none;
            li {
                & + li {
                    margin-top: 0px;
                }
                a {
                    font-size: 12px;
                    padding: 3px 0;
                    cursor: pointer;
                }
            }
        }
        border-top: solid 1px ${(props) => props.theme.borderColor};
    }
`;

export const Copy = styled.div`
    margin-top: 50px;
    padding: 10px 0;
    border-top: solid 1px ${(props) => props.theme.borderColor};
    background-color: ${(props) => props.theme.grayLight};
    box-shadow: inset 0 1px 0 ${(props) => props.theme.white};
    color: ${(props) => props.theme.primaryDark};
    & ${Button} {
        margin-bottom: 0 !important;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        a {
            color: ${(props) => props.theme.primaryDark};
            cursor: pointer;
            text-decoration: underline;
        }
    }

    @media (max-width: 768px) {
        margin-top: 0;
        & ${Button} {
            font-size: 12px;
        }
    }
`;

export const Language = styled(Dropdown)`
    margin-right: 10px;
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;

export const ConsentButton = styled.a`
    border: none;
    margin: 10px 0;
    color: ${(props) => props.theme.gray};
    font-size: 14px;
    background: transparent;
    text-decoration: underline;
`;
