import styled from "styled-components";
import { Column, Title, Box } from "../../sdk/Layout";
import { Field, Control } from "../../sdk/Form";

export const Left = styled(Column)`
    width: 420px;
    text-align: right;
    padding-right: 30px;
    ${Title} {
        border-left: solid 2px ${(props) => props.theme.primaryDarkMode};
    }

    @media (max-width: 980px) {
        width: 200px;
        & ${Title} {
            font-size: 20px;
        }
    }
    @media (max-width: 768px) {
        width: 97%;
        padding-right: 0;
        text-align: center;
        & ${Title} {
            font-size: 20px;
            margin-bottom: 0;
            border-left: 0;
            margin-top: 20px;
        }
    }
`;

export const Right = styled(Column)`
    font-size: 16px;
    ${Box} {
        padding-top: 50px;
    }
`;

export const Wrap = styled.div`
    padding: 20px 100px;

    @media (max-width: 1215px) {
        padding: 20px;
    }
    @media (max-width: 768px) {
        padding: 0px;
    }
`;

export const ErrorLabel = styled.small`
    color: ${(props) => props.theme.error};
    font-size: 10px;
`;

export const FieldExtended = styled(Field)`
    max-width: 336px;

    .fit-height {
        height: fit-content;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const ControlExtended = styled(Control)`
    .PhoneInput {
        display: flex;
        align-items: center;
    }

    .is-invalid.PhoneInput > .PhoneInputInput {
        border-color: #cc0f35;
    }

    .PhoneInputInput {
        /* The phone number input stretches to fill all empty space */
        /* flex: 1; */
        /* The phone number input should shrink
 to make room for the extension input */
        /* min-width: 0;
        border: 0;
        outline: none; */
    }

    .PhoneInputCountryIcon {
        width: calc(1em * 1.5);
        height: 1em;
    }

    .PhoneInputCountryIcon--square {
        width: 1em;
    }

    .PhoneInputCountryIcon--border {
        background-color: rgba(0, 0, 0, 0.1);

        box-shadow: 0 0 0 var(1px) rgba(0, 0, 0, 0.5),
            inset 0 0 0 var(1px) rgba(0, 0, 0, 0.5);
    }

    .PhoneInputCountryIconImg {
        display: block;
        max-width: 50px;
        width: 100%;
        height: 100%;
    }

    .PhoneInputInternationalIconPhone {
        opacity: 0.8;
    }

    .PhoneInputInternationalIconGlobe {
        opacity: 0.65;
    }

    .PhoneInputCountry {
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-right: 0.35em;
    }

    .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
    }

    .PhoneInputCountrySelect[disabled] {
        cursor: default;
    }

    .PhoneInputCountrySelectArrow {
        display: block;
        content: "";
        width: 0.3em;
        height: 0.3em;
        margin-left: 0.35em;
        border-style: solid;
        border-color: inherit;
        border-top-width: 0;
        border-bottom-width: 1px;
        border-left-width: 0;
        border-right-width: 1px;
        transform: rotate(45deg);
        opacity: 0.45;
    }

    .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        + .PhoneInputCountrySelectArrow {
        opacity: 1;
        color: inherit;
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
        box-shadow: 0 0 0 var(1px) #03b2cb, inset 0 0 0 var(1px) #03b2cb;
    }

    .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        .PhoneInputInternationalIconGlobe {
        opacity: 1;
        color: #03b2cb;
    }
`;

export const InputExtended = styled.div`
    input[type="text"] {
        padding-right: 30px;
    }
    button {
        cursor: pointer;
        background: none;
        border: none;
        position: absolute;
        top: 35%;
        right: 12px;
    }
`;
