import React from "react";
import styled from "styled-components";
import MegaLink from "./components/MegaLink";

// import MovilHeader from "./components/MovilHeader";
import Search from "../Search";
// import Logo from "./icons/Logo";
import Logo from "../Logo";
import PersonIcon from "./icons/PersonIcon";
import HeartSolidIcon from "./icons/HeartSolidIcon";
import HeartIcon from "./icons/HeartIcon";
import HeartFillIcon from "./icons/HeartFillIcon";
import CartIcon from "./icons/CartIcon";
import BagIcon from "./icons/BagIcon";
import BagFillIcon from "./icons/BagFillIcon";
import TagIcon from "./icons/TagIcon";

import PercentIconCircle from "./icons/PercentIconCircle";
import Navicon from "./icons/Navicon";
import HomeIcon from "./icons/HomeIcon";
import HomeFillIcon from "./icons/HomeFillIcon";
import UserIcon from "./icons/UserIcon";
import UserFillIcon from "./icons/UserFillIcon";
// import { Icon } from "../../sdk/Button";
// import { Dropdown } from "../../sdk/Elements";
// import VMenuIcon from "./icons/VMenuIcon";
import BackIcon from "./icons/BackIcon";
import renderIf from "../../utils/renderIf";
import InternalLink from "../InternalLink";
import Hamburger from "../Hamburger";
import useClickOutside from "../../hooks/useClickOutside";
import useBrand from "../../hooks/useBrand";
import StoreLogo from "./components/StoreLogo";
import { Button } from "../../sdk/Button";
import { config as pageConfig } from "../../../config";
import { getDefaultUrl } from "../../utils/SEOFieldsHelmet";

// import Icon from "../Icon";

const strings = {
    seAllResults: "Ver todos los resultados",
    loginToSearch: "Debe iniciar sesión para poder buscar.",
    comercialize: "Registrate",
};

const DiscountItem = styled.div`
    padding: 4px 8px;
    background-color: ${({theme}) => theme.complementary};
    border: 1px solid ${({theme}) => theme.link};
    border-radius: 4px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        font-weight: bold;
        color: white;
    }

    span {
        font-size: 14px;
    }
`;

export const checkTags = (title, tags, jsonSettings, user) => {
    return true;
};

function Header({
    menusData,
    isLoggedIn,
    user,
    lang,
    jsonSettings,
    _t,
    registerLink,
    gotoRegister,
    stats,
    searchProducts,
    goToSearch,
    viewItem,
    path,
    placeholder,
    hasBackButton,
    // searchResults,
    suggestions,
    requestBrandAccess,
    brandSlug,
}) {
    const [languagePicker, setLanguagePicker] = React.useState(false);
    const [accountMenu, setAccountMenu] = React.useState(false);
    const [isRequestingBrandAccess, setIsRequestingBrandAccess] =
        React.useState(false);
    const dropdownRef = React.useRef();

    const [responsiveMenuActive, setResponsiveMenuActive] =
        React.useState(false);

    useClickOutside(dropdownRef, () => setAccountMenu(false));

    const [brand, isLoadingBrand, refreshBrand] = useBrand();

    const brandSlugIsSet = brandSlug && true;
    const hasBrandAccess = brand && brand.isApprovedByBrand;
    const didBrandRequest = brand && brand.didBrandRequest;

    let config;
    // if (menusData.length < 2) return null;

    if (brandSlugIsSet == null) {
        config = menusData && menusData[0] ? menusData[0].menu : [];
        if (isLoggedIn)
            config = menusData && menusData[1] ? menusData[1].menu : [];
    } else {
        config = brand && brand.mainMenu ? brand.mainMenu.menu : [];
    }

    const toggleLMenu = () => {
        setLanguagePicker(!languagePicker);
    };

    const toggleAccMenu = () => {
        setAccountMenu(!accountMenu);
    };

    const toggleRespMenu = () => {
        setResponsiveMenuActive(!responsiveMenuActive);
    };
    const goBack = () => {
        history.back();
    };

    const buildHeader = () => {
        if (config == null) return;
        const tags = user ? user.tags : [];
        const header = config.map((index, i) => {
            const title = index.title.toLowerCase();

            if (!checkTags(title, tags, jsonSettings, user)) return null;

            if (title === "action items") return null;

            if (index.hide) return null;

            if (index.children == null || index.children.length === 0) {
                return (
                    <li key={index.title + "header" + i}>
                        <InternalLink {...index}>{index.title}</InternalLink>
                    </li>
                );
            }
            // if (tags != null && tags.indexOf(indexOf.title) < 0) return null
            return (
                <MegaLink
                    {...index}
                    key={index.title + "header" + i}
                    index={i}
                    path={path}
                />
            );
        });

        return header;
    };

    const handleRequestAccess = () => {
        setIsRequestingBrandAccess(true);
        requestBrandAccess({ brandSlug })
            .then((resp) => {
                refreshBrand();
                setIsRequestingBrandAccess(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        const keys = Object.keys(strings);
        for (var i = keys.length - 1; i >= 0; i--) {
            strings[keys[i]] = _t(keys[i]);
        }
    }, []);

    React.useEffect(() => {
        setResponsiveMenuActive(false);
        if ("activeElement" in document) {
            try {
                document.activeElement.blur();
            } catch (e) {
                document.body.blur();
            }
        }

        if (typeof window !== "undefined") {
            try {
                const elmnt = document.getElementById("permanentHeader");
                document.getElementById("main-content").style.paddingTop =
                    (elmnt.offsetHeight < 106 ? 106 : elmnt.offsetHeight) +
                    "px";
            } catch (err) {
                document.getElementById("main-content").style.paddingTop =
                    "106px";
            }
        }
    }, [path]);

    return (
        <>
            <div
                id="permanentHeader"
                className={`header-wrapper ${
                    brandSlugIsSet ? "store-header" : ""
                }`}
            >
                <header className="header hide-on-standalone">
                    <div className="header-left">
                        {brandSlugIsSet ? (
                            <StoreLogo
                                isLoggedIn={isLoggedIn}
                                isLoadingBrand={isLoadingBrand}
                                brand={brand}
                            />
                        ) : (
                            <InternalLink
                                to={isLoggedIn ? "/home-login/" : "/"}
                            >
                                <Logo className="logo" />
                            </InternalLink>
                        )}

                        <div className="is-hidden-980 is-fullwidth">
                            <Search
                                onChange={searchProducts}
                                goToSearch={goToSearch}
                                viewItem={viewItem}
                                placeholder={
                                    brandSlugIsSet
                                        ? `Buscar en ${
                                              brand && brand.brand
                                                  ? brand.brand.name
                                                  : ""
                                          }...`
                                        : placeholder
                                }
                                suggestions={suggestions}
                                isLoggedIn={isLoggedIn}
                                strings={strings}
                                gotoRegister={gotoRegister}
                            />
                        </div>
                    </div>
                    <div className="header-right">
                        {/* {renderIf(isLoggedIn)(
                            <div className="header-item is-hidden-580 ">
                                <div className="add space">
                                    <InternalLink to={"/referral"}>
                                        <TagIcon />
                                        {_t("win200")}
                                    </InternalLink>
                                </div>
                            </div>
                        )} */}
                        {renderIf(
                            (isLoggedIn && !brandSlugIsSet) || hasBrandAccess
                        )(
                            <div className="header-item is-hidden-580 ">
                                <DiscountItem>
                                    <InternalLink to="/collection/sale">
                                        <PercentIconCircle />
                                        <span>{_t("sale")}</span>
                                    </InternalLink>
                                </DiscountItem>
                            </div>
                        )}
                        {renderIf(!isLoggedIn)(
                            <div
                                className={`header-item ${
                                    isLoggedIn ? "" : "hide-right-separator"
                                }`}
                            >
                                <div className="auth-buttons">
                                    <InternalLink
                                        to="/account/login/"
                                        className="button is-white"
                                    >
                                        Login
                                    </InternalLink>
                                    <a
                                        onClick={gotoRegister}
                                        to={registerLink()}
                                        className="button is-primary"
                                    >
                                        {_t("registerToBuy")}
                                    </a>
                                </div>
                                <span className="divisor"></span>
                            </div>
                        )}

                        {renderIf(
                            !hasBrandAccess && brandSlugIsSet && isLoggedIn
                        )(
                            <div className="header-item">
                                <div className="auth-buttons">
                                    <Button
                                        onClick={handleRequestAccess}
                                        primary={!didBrandRequest}
                                        static={didBrandRequest}
                                        loading={
                                            isRequestingBrandAccess ||
                                            isLoadingBrand
                                                ? isRequestingBrandAccess
                                                : undefined
                                        }
                                    >
                                        {didBrandRequest
                                            ? _t("pendingBrandRequest")
                                            : _t("requestAccess")}
                                    </Button>
                                </div>
                                {/* <span className="divisor"></span> */}
                            </div>
                        )}
                        {renderIf(
                            (isLoggedIn && !brandSlugIsSet) || hasBrandAccess
                        )(
                            <div className="header-item">
                                <ul className="icons">
                                    <li ref={dropdownRef}>
                                        <a onClick={toggleAccMenu}>
                                            <PersonIcon />
                                        </a>
                                        <ul
                                            className={`dropdown ${
                                                accountMenu ? "is-active" : ""
                                            }`}
                                        >
                                            <li>
                                                <InternalLink to="/account/profile">
                                                    {_t("myAccount")}
                                                </InternalLink>
                                            </li>
                                            {/* <li>
                    <InternalLink>Mis favoritos</InternalLink>
                  </li> */}
                                            <li>
                                                <InternalLink to="/orders">
                                                    {_t("myOrders")}
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <InternalLink to="/claims">
                                                    {_t("myClaims")}
                                                </InternalLink>
                                            </li>
                                            {renderIf(pageConfig.IS_NORITEX)(
                                                <li>
                                                    <InternalLink to="/image-search/">
                                                        {_t("imageSearch")}
                                                    </InternalLink>
                                                </li>
                                            )}
                                            <li>
                                                <InternalLink to="/ntxmedia/">
                                                    Mayorea-Media
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <a
                                                    href={
                                                        getDefaultUrl() +
                                                        "/plugins/index.html#/"
                                                    }
                                                >
                                                    {_t("tendencieTable")}
                                                </a>
                                            </li>
                                            <li>
                                                <InternalLink to="/help/">
                                                    {_t("help")}
                                                </InternalLink>
                                            </li>
                                            {/* <li>
                    <InternalLink>Soporte</InternalLink>
                  </li> */}
                                            <li>
                                                <InternalLink to="/home-login/image-export/">
                                                    {_t("productImages")}
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <InternalLink to="/wishlist/">
                                                    {_t("myList")}
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <InternalLink to="/account/logout/">
                                                    Logout
                                                </InternalLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <InternalLink to="/wishlist">
                                            <HeartSolidIcon />
                                        </InternalLink>
                                    </li>

                                    <li>
                                        <InternalLink to="/cart">
                                            <CartIcon />
                                            <span className="cart-counter">
                                                ({stats ? stats.count : "0"})
                                            </span>
                                        </InternalLink>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </header>
                <div className="nav-container">
                    {/* hamburger menu aqui */}
                    <a
                        className="desktop-navicon is-hidden-980"
                        onClick={toggleRespMenu}
                    >
                        <Navicon></Navicon>
                    </a>
                    <nav className="is-hidden-980">
                        <ul>{buildHeader()}</ul>
                    </nav>
                    <div className="language-picker is-hidden-980">
                        <span className="current" onClick={toggleLMenu}>
                            <img
                                alt="spain-flag"
                                src={
                                    lang === "es"
                                        ? "https://www.noritex.com/sp-logo.jpg"
                                        : lang === "pt"
                                        ? "https://www.noritex.com/brazil.jpg"
                                        : "https://www.noritex.com/us-logo.jpg"
                                }
                            />
                        </span>
                        <ul
                            className={`dropdown ${
                                languagePicker ? "is-active" : ""
                            }`}
                        >
                            <li>
                                <a href="/pt/">
                                    <img
                                        alt="Portugues-flag"
                                        src="https://www.noritex.com/brazil.jpg"
                                    />
                                    <span>Portugues</span>
                                </a>
                            </li>
                            <li>
                                <a href="/en/">
                                    <img
                                        alt="american-flag"
                                        src="https://www.noritex.com/us-logo.jpg"
                                    />
                                    <span>English</span>
                                </a>
                            </li>
                            <li>
                                <a href="/es/">
                                    <img
                                        alt="spain-flag"
                                        src="https://www.noritex.com/sp-logo.jpg"
                                    />
                                    <span>Español</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="is-visible-980 hide-on-standalone">
                        <button
                            className="clear-btn navicon"
                            onClick={toggleRespMenu}
                            aria-label={_t("menu")}
                        >
                            <Navicon selected={responsiveMenuActive} />
                        </button>
                    </div>

                    {renderIf(!hasBackButton)(
                        <div className="is-visible-980 show-on-standalone">
                            <InternalLink
                                className="clear-btn navicon"
                                to="/stand-alone"
                                aria-label={_t("menu")}
                            >
                                <Navicon selected={responsiveMenuActive} />
                            </InternalLink>
                        </div>
                    )}
                    {renderIf(hasBackButton)(
                        <div className="is-visible-980 show-on-standalone">
                            <button
                                className="clear-btn navicon"
                                onClick={goBack}
                                aria-label={_t("back")}
                            >
                                <BackIcon />
                            </button>
                        </div>
                    )}

                    <div className="is-visible-980 is-fullwidth">
                        <Search
                            onChange={searchProducts}
                            goToSearch={goToSearch}
                            viewItem={viewItem}
                            placeholder={placeholder}
                            suggestions={suggestions}
                            isLoggedIn={isLoggedIn}
                            strings={strings}
                            gotoRegister={gotoRegister}
                        />
                    </div>
                    {/* <div className="is-visible-980 hide-on-standalone">
                        <InternalLink to="/cart/" tabIndex={0} role="link">
                            <BagIcon />
                        </InternalLink>
                    </div> */}
                    {/* <div className="is-visible-980 show-on-standalone">
                        <Dropdown isActive isRight>
                            <Icon><VMenuIcon /></Icon>
                            <div>
                                <div>
                                    <ul>
                                        <li><a href="">
                                            <Icon><HomeIcon/></Icon>
                                            Inicio</a></li>
                                        <li><a href="">
                                            <Icon><HeartIcon/></Icon>
                                            Lista de deseos</a></li>
                                        <li><a href="">
                                            <Icon><UserIcon/></Icon>
                                            Mi cuenta</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Dropdown>
                    </div> */}

                    {/* <MovilHeader
                        responsiveMenuActive={responsiveMenuActive}
                        checkTags={checkTags}
                        jsonSettings={jsonSettings}
                        config={config}
                        toggleRespMenu={toggleRespMenu}
                        user={user}
                        title={_t("menu")}
                    /> */}
                </div>
            </div>
            {renderIf(!hasBackButton)(
                <div className="stand-alone-menu standalone-hide-force">
                    <ul>
                        {renderIf(isLoggedIn)(
                            <li>
                                <InternalLink
                                    to="/home-login/"
                                    activeClassName="selected"
                                    tabIndex={0}
                                    role="link"
                                >
                                    <HomeIcon />
                                    <span className="selected-icon">
                                        <HomeFillIcon />
                                    </span>
                                </InternalLink>
                            </li>
                        )}
                        {renderIf(!isLoggedIn)(
                            <li>
                                <InternalLink
                                    to="/"
                                    activeClassName="selected"
                                    tabIndex={0}
                                    role="link"
                                >
                                    <HomeIcon />
                                    <span className="selected-icon">
                                        <HomeFillIcon />
                                    </span>
                                </InternalLink>
                            </li>
                        )}
                        <li>
                            <InternalLink
                                to="/wishlist/"
                                activeClassName="selected"
                                tabIndex={0}
                                role="link"
                            >
                                <HeartIcon />
                                <span className="selected-icon">
                                    <HeartFillIcon />
                                </span>
                            </InternalLink>
                        </li>
                        <li>
                            <InternalLink
                                to="/cart/"
                                activeClassName="selected"
                                tabIndex={0}
                                role="link"
                            >
                                <BagIcon />
                                <span className="selected-icon">
                                    <BagFillIcon />
                                </span>
                            </InternalLink>
                        </li>
                        {/* <li className=""> */}
                        {/*     <InternalLink */}
                        {/*         to="/stand-alone/" */}
                        {/*         className={" click"} */}
                        {/*         activeClassName=" selected" */}
                        {/*         tabIndex={0} */}
                        {/*         role="link" */}
                        {/*     > */}
                        {/*         <Navicon selected={responsiveMenuActive} /> */}
                        {/*     </InternalLink> */}
                        {/* </li> */}
                        <li>
                            <InternalLink
                                to="/standalonemyaccount/"
                                activeClassName="selected"
                                tabIndex={0}
                                role="link"
                            >
                                <UserIcon />
                                <span className="selected-icon">
                                    <UserFillIcon />
                                </span>
                            </InternalLink>
                        </li>
                    </ul>
                </div>
            )}
            <Hamburger
                isActive={responsiveMenuActive}
                toggleMenu={toggleRespMenu}
                checkTags={checkTags}
                jsonSettings={jsonSettings}
                config={config}
                isLoggedIn={isLoggedIn}
                user={user}
                _t={_t}
                lang={lang}
            />
        </>
    );
}

export default React.memo(Header);
