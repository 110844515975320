import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Blueberry from "../../../blueberry";
import useUser from "../../../blueberry/hooks/useUser";
import useJsonSettings from "../../hooks/useJsonSettings";
import { Container, Section, Title, Heading } from "../../sdk/Layout";

import { getTag, rules } from "../../utils";
import ProductsScrollable from "../ProductsScrollable";

const SectionExtended = styled(Section)`
    position: relative;
    z-index: 1;
    background-color: ${(props) => props.theme.grayLight};
    @media (max-width: 768px) {
        padding-right: 0;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

const ContainerExtended = styled(Container)`
    @media (max-width: 768px) {
        overflow-x: auto;
        padding-bottom: 10px;
    }
`;

const Recomendations = ({ toggleModalProduct, addToCart, viewItem, _t }) => {
    const user = useUser();

    const jsonSettings = useJsonSettings();

    const [isLoading, setIsLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    const [didFirstLoad, setDidFirstLoad] = React.useState(false);
    const [hideComponent, setHideComponent] = React.useState(false);
    const [recomendations, setRecomendations] = React.useState([]);

    const { ref, inView } = useInView({
        threshold: 0,
    });

    React.useEffect(() => {
        if (!didFirstLoad && inView) {
            setIsLoading(true);

            Blueberry.homePage({
                filter: "",
                limit: 18,
                page: 1,
                limitPerRow: 10,
                rotationRate: 0.1,
                includeBestsellers: true,
                includePersonal: true,
                recentlyViewed: true,
            })
                .then((resp) => {
                    setRecomendations(resp);
                    setIsLoading(false);
                    setDidFirstLoad(true);
                })
                .catch(() => {
                    setDidFirstLoad(false);
                    setIsLoading(false);
                    setError(true);
                });
        }
    }, [inView]);

    if (hideComponent) return null;
    return (
        <SectionExtended ref={ref}>
            <ContainerExtended isFluid>
                <Heading isCentered>
                    <h3>{_t("basedOnYourHistory")}</h3>
                </Heading>
                {isLoading ? (
                    <>
                        <ProductsScrollable
                            viewItem={viewItem}
                            addToCart={addToCart}
                            isLoading={isLoading}
                            onQuickView={toggleModalProduct}
                        />
                        <ProductsScrollable
                            viewItem={viewItem}
                            addToCart={addToCart}
                            isLoading={isLoading}
                            onQuickView={toggleModalProduct}
                        />
                        <ProductsScrollable
                            viewItem={viewItem}
                            addToCart={addToCart}
                            isLoading={isLoading}
                            onQuickView={toggleModalProduct}
                        />
                        <ProductsScrollable
                            viewItem={viewItem}
                            addToCart={addToCart}
                            isLoading={isLoading}
                            onQuickView={toggleModalProduct}
                        />
                        <ProductsScrollable
                            viewItem={viewItem}
                            addToCart={addToCart}
                            isLoading={isLoading}
                            onQuickView={toggleModalProduct}
                        />
                    </>
                ) : null}

                {recomendations.map((index, i) => (
                    <ProductsScrollable
                        index={index}
                        key={`recomends-${i}`}
                        viewItem={viewItem}
                        addToCart={addToCart}
                        onQuickView={toggleModalProduct}
                    />
                ))}
            </ContainerExtended>
        </SectionExtended>
    );
};

export default Recomendations;
