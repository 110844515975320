import React from "react";
import InternalLink from "../../InternalLink";

function StoreLogo({ isLoggedIn, isLoading, brand }) {
    return (
        <>
            <InternalLink to={isLoggedIn ? "/home-login/" : "/"} ignoreBrand>
                <div className="store-exit-btn"></div>
            </InternalLink>
            <InternalLink to={"/brand"} className="store-logo">
                {isLoading ? (
                    <div className="store-loader" />
                ) : (
                    <img
                        src={brand && brand.brand ? brand.brand.imageLogo : ""}
                        alt={brand && brand.brand ? brand.brand.name : ""}
                    />
                )}
            </InternalLink>
        </>
    );
}

export default React.memo(StoreLogo);
