import React, { useState } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Button } from "../../sdk/Button";
import { useTranslation } from "../../../lang";

export const ButtonExtended = styled(Button)`
    position: fixed;
    top: 130px;
    display: block;
    margin: 0 -50px;
    z-index: 301;
    border-radius: 20px;
    left: 50%;
    transition: all 200ms ease-in;
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        --safe-area-inset-top: env(safe-area-inset-top);

        top: calc(var(--safe-area-inset-top) + 80px);
    }
`;

function BackButton() {
    const [_t] = useTranslation();
    const [headerStyle, setHeaderStyle] = useState({
        visibility: "hidden",
        transition: "all 200ms ease-in",
        transform: "translate(0, -100%)",
    });

    const handleBackButton = () => {
        window.scrollTo(0, 0);
    };

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isVisible = currPos.y < -600;

            const shouldBeStyle = {
                visibility: isVisible ? "visible" : "hidden",
                transition: `all 200ms ${isVisible ? "ease-in" : "ease-out"}`,
                transform: isVisible ? "none" : "translate(0, -100%)",
            };

            if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle))
                return;

            setHeaderStyle(shouldBeStyle);
        },
        [headerStyle]
    );

    return (
        <ButtonExtended
            className="button_back-to-top"
            onClick={handleBackButton}
            primary
            style={{ ...headerStyle }}
        >
            {_t("setWindowTop")}
        </ButtonExtended>
    );
}

export default BackButton;
