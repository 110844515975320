import React from "react";

export default function PercentIconCircle() {
    return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="white"
				d="M7.91667 0.583313C3.5625 0.583313 0 4.14581 0 8.49998C0 12.8541 3.5625 16.4166 7.91667 16.4166C12.2708 16.4166 15.8333 12.8541 15.8333 8.49998C15.8333 4.14581 12.2708 0.583313 7.91667 0.583313ZM5.40708 4.58123C6.18292 4.58123 6.80833 5.20665 6.80833 5.9904C6.80833 6.76623 6.18292 7.39165 5.40708 7.39165C4.62333 7.39165 3.99792 6.76623 3.99792 5.9904C3.99792 5.20665 4.62333 4.58123 5.40708 4.58123ZM10.4658 12.4583C9.69 12.4583 9.06458 11.825 9.06458 11.0491C9.06458 10.2733 9.69 9.6479 10.4658 9.6479C11.2417 9.6479 11.875 10.2733 11.875 11.0491C11.875 11.825 11.2417 12.4583 10.4658 12.4583ZM5.14583 12.4821L3.95833 11.2946L10.7112 4.54165L11.8987 5.72915L5.14583 12.4821Z"
			/>
		</svg>
    );
}
