/* eslint-disable react/prop-types */
import React from "react";
import {
	getProductCase,
	isPackagingNumeric,
	productHasPrice,
	ProductPrice,
} from "./index";
import renderIf from "../../utils/renderIf";
import InternalLink from "../InternalLink";
import { useTranslation } from "../../../lang";
import Blueberry from "../../../blueberry";
import useNotification from "../../hooks/useNotification";
import ModalPrecioTienda from "./ModalPrecioTienda";
import Icons2 from "../Icons2";
import { StorePrice, New, LinkMoreDetails, MoreInfoRow } from "./style";
import HelpTooltip from "../HelpTooltip";
import { isNumeric, numberWithCommas } from "../../utils";

export function getDetailValue(product, field) {
	if (product == null) return "";
	if (typeof product.detalle_eng === "object" && product.detalle_eng != null) {
		return product.detalle_eng[field] || "";
	}
	if (typeof product.detalle_pt === "object" && product.detalle_pt != null) {
		return product.detalle_pt[field] || "";
	}
	if (typeof product.detalle === "object" && product.detalle != null) {
		return product.detalle[field] || "";
	}

	return "";
}

export function getCustomFieldValue(product, field, lang) {
	if (product == null) return "";

	if (lang && typeof product[field] === "object" && product[field] != null) {
		return product[field][lang];
	} else if (typeof product[field] === "string") {
		return product[field];
	}

	return "";
}

function DetailProductTable({
	product,
	isFair,
	isLoggedIn,
	jsonSettings,
	refetch,
}) {
	const [_t, lang] = useTranslation();

	const [handleNotify, NotifyProfile] = useNotification({
		timeout: 3000,
	});
	const [isExpanded, setIsExpanded] = React.useState(false);
	const [isModalVisible, setIsModalVisitble] = React.useState(false);
	const [didBrandRequest, setdidBrandRequest] = React.useState(false);

	const [isDone, setIsDone] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [reachedMaximunRequest, setRequestMaximun] = React.useState(false);

	const packaging = getDetailValue(product, "packaging");
	const hasPrice = productHasPrice(product);

	const toggleModal = () => {
		setIsModalVisitble((prevState) => !prevState);
	};

	const closeModal = () => {
		setIsModalVisitble(false);
	};

	const handleColorRequest = () => {
		setIsLoading(true);
		Blueberry.requestProductData({
			ask: "color",
			productId: product._id,
		})
			.then((resp) => {
				setIsLoading(false);
				setIsDone(true);

				handleNotify({
					paragraph: _t("informationRequested"),
					info: true,
				});
			})
			.catch((err) => {
				if (
					err &&
					err.reason === "reached maximun number of requests allowed"
				) {
					setRequestMaximun(true);
					setIsLoading(false);
					handleNotify({
						paragraph: _t("reachedMaximunRequest"),
						error: true,
					});
				} else {
					setIsDone(true);
					setIsLoading(false);
					handleNotify({
						paragraph: _t("informationRequested"),
						info: true,
					});
				}
			});
	};

	const toggleMoreInfo = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleRequestAccess = () => {
		setdidBrandRequest(true);
		Blueberry.insertBrandRequest({ brandId: product.brandId })
			.then((resp) => {
				setdidBrandRequest(true);
			})
			.catch((err) => {
				setdidBrandRequest(false);
				console.log(err);
			});
	};

	React.useEffect(() => {
		setIsDone(false);
	}, [product]);

	return (
		<>
			<table>
				<tbody>
					<tr className={product.price_d ? "price_d" : ""}>
						<th align={`right`}>
							{_t("price")} {product.price_d ? _t("direct") : ""}
						</th>
						<td>
							<ProductPrice
								price={product.price}
								price_d={product.price_d}
								price_usa={product.price_usa}
								compare_at_price={product.compare_at_price}
							/>
							{renderIf(!isLoggedIn && !hasPrice)(
								<InternalLink to="/account/login/">
									{_t("loginToViewPricing")}
								</InternalLink>,
							)}
							{renderIf(
								!didBrandRequest && isLoggedIn && !hasPrice,
							)(
								<a onClick={handleRequestAccess}>
									{_t("requestAccess")}
								</a>,
							)}
							{renderIf(
								didBrandRequest && isLoggedIn && !hasPrice,
							)(
								<span onClick={handleRequestAccess}>
									{_t("pendingBrandRequest")}
								</span>,
							)}
						</td>
					</tr>
					{renderIf(isLoggedIn && hasPrice)(
						<tr>
							<th align="right">{_t("priceStore")}</th>
							<td>
								<StorePrice>
									{renderIf(
										product.price_for_user_store == null,
									)(
										<>
											<a onClick={toggleModal}>
												{_t("configure")}
											</a>
											<HelpTooltip
												text={_t("helpPriceStore")}
											/>
										</>,
									)}
									{renderIf(product.price_for_user_store)(
										<>
											<span className="price">
												{product.price_for_user_store}
											</span>
											<a onClick={toggleModal}>
												<span>{_t("edit")}</span>
												<Icons2 icon="pencil"></Icons2>
											</a>
										</>,
									)}
									<New>{_t("new")}</New>
								</StorePrice>
							</td>
						</tr>,
					)}
					{renderIf(product.available && product.available > 0)(
						<tr>
							<th align="right">{_t("available")}</th>
							<td>{product.available}</td>
						</tr>,
					)}
					{renderIf(isPackagingNumeric(product))(
						<tr>
							<th align="right">{_t("unitPrice")}</th>
							<td>{getProductCase(product)}</td>
						</tr>,
					)}
					<tr>
						<th align="right">{_t("minQuantity")}</th>
						<td>{isFair ? product.min_venta : product.multiple}</td>
					</tr>
					{renderIf(
						product.detalle != null ||
							product.detalle_eng != null ||
							product.detalle_pt != null,
					)(
						<>
							<tr>
								<th align="right">{_t("collection")}</th>
								<td>{getDetailValue(product, "collection")}</td>
							</tr>
							<tr>
								<th align="right">{_t("dimensions")}</th>
								<td>{getDetailValue(product, "dimensions")}</td>
							</tr>
							<tr>
								<th align="right">{_t("material")}</th>
								<td>{getDetailValue(product, "material")}</td>
							</tr>
							<tr>
								<th align="right">{_t("packaging")}</th>
								<td>{packaging}</td>
							</tr>
							<tr>
								<th align="right">{_t("unitOfMeasure")}</th>
								<td>{getDetailValue(product, "unit")}</td>
							</tr>
							{
								product.weight != null && (
									<tr>
										<th align="right">{_t("weight")}</th>
										<td>
											{`${product.weight} kg`}
										</td>
									</tr>
								)
							}
							{product.color != null ? (
								<tr>
									<th align="right">{_t("color")}</th>
									<td>
										{getCustomFieldValue(
											product,
											"color",
											lang,
										)}
									</td>
								</tr>
							) : jsonSettings &&
							  jsonSettings.showAskForVideo &&
							  isLoggedIn ? (
								<tr>
									<th align="right">{_t("color")}</th>
									<td>
										{reachedMaximunRequest ? (
											<span>
												{_t("reachedMaximunRequest")}
											</span>
										) : !isDone ? (
											<a onClick={handleColorRequest}>
												{_t("request")} {_t("color")}
											</a>
										) : (
											<span>
												{_t("informationRequested")}
											</span>
										)}
									</td>
								</tr>
							) : null}
						</>,
					)}
					<NotifyProfile />
					{isExpanded && (
						<>
							{renderIf(product.volumetricCapacity)(
								<MoreInfoRow>
									<th align="right">{_t("volCapacity")}</th>
									<td>
										{product.volumetricCapacity
											? product.volumetricCapacity
											: ""}
									</td>
								</MoreInfoRow>,
							)}
							{renderIf(
								product.mechanism && product.mechanism[lang],
							)(
								<MoreInfoRow>
									<th align="right">{_t("mechanism")}</th>
									<td>
										{product.mechanism
											? product.mechanism[lang]
											: ""}
									</td>
								</MoreInfoRow>,
							)}
							{renderIf(product.batQty != null)(
								<MoreInfoRow>
									<th align="right">
										Batt. {_t("quantity")}
									</th>
									<td>
										{product.batQty ? product.batQty : ""}
									</td>
								</MoreInfoRow>,
							)}
							{renderIf(
								product.specialFeatures &&
									product.specialFeatures[lang],
							)(
								<MoreInfoRow>
									<th align="right">
										{_t("notasEspeciales")}
									</th>
									<td>
										{product.specialFeatures
											? product.specialFeatures[
													lang
											  ].join(", ")
											: ""}
									</td>
								</MoreInfoRow>,
							)}
							{renderIf(
								product.otherRemarks &&
									product.otherRemarks.indexOf("MOQ") <= -1,
							)(
								<MoreInfoRow>
									<th align="right">{_t("otherNotes")}</th>
									<td>{product.otherRemarks}</td>
								</MoreInfoRow>,
							)}
						</>
					)}
				</tbody>
			</table>
			{renderIf(
				product.mechanism ||
					product.otherRemarks ||
					product.specialFeatures ||
					product.batQty ||
					product.volumetricCapacity,
			)(
				<div>
					<LinkMoreDetails
						expanded={isExpanded}
						onClick={() => toggleMoreInfo()}>
						{isExpanded ? "Menos" : "Más"} detalles
					</LinkMoreDetails>
				</div>,
			)}
			{renderIf(isModalVisible)(
				<ModalPrecioTienda
					closeModal={closeModal}
					product={product}
					refetch={refetch}
				/>,
			)}
		</>
	);
}

export default React.memo(DetailProductTable);
